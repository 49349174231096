import React, { Component } from "react";
import EventInfoWithIcons from "./eventInfoWithIcons";
import {
  determineTitleWithDuration,
  CapitalizeFirstLetterOfEveryWord,
  IsEmptyObject,
  doesTitleIncludeVariableBlocks,
  getUserEmail,
} from "../services/commonUsefulFunctions";
import classNames from "classnames";
import HostDetails from "./hostDetails";
import VimcalVLogo from "./logo/vimcalVLogo";

class AvailabilityLinkEventInfo extends Component {
  render() {
    const {
      additionalClassName,
      alignItems,
      selectedTimeZone,
      shouldShowTimeZoneDropdown,
      onSelectTimeZone,
      info,
      isGroupVoteSpreadSheet,
    } = this.props;

    const { isMobile } = info;

    const shouldRenderLogoWithTitle = isGroupVoteSpreadSheet && !isMobile;
    return (
      <div
        className="availability-event-info"
        style={
          isMobile
            ? {
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: alignItems ?? "flex-start",
                padding: 14,
              }
            : {
                paddingTop: 20,
                paddingBottom: isGroupVoteSpreadSheet ? 10 : 24,
                paddingLeft: 30,
              }
        }
      >
        {this.renderProfileSection()}

        {(info.duration || info.isGroupVote) && (
          <div
            className={classNames("availability-link-title", shouldRenderLogoWithTitle ? "flex justify-between" : "")}
            style={{ fontSize: 20 }}
          >
            {this.determineLargeTitle()}
            {shouldRenderLogoWithTitle ? this.renderOnlyVimcalVLogo() : null}
          </div>
        )}

        <EventInfoWithIcons
          info={info}
          additionalClassName={classNames(additionalClassName)}
          containerClassName={this.getEventInfoWithIconsContainerClassname(isMobile)}
          addMarginRightToEachSection={true}
          selectedTimeZone={selectedTimeZone}
          onSelectTimeZone={onSelectTimeZone}
          shouldShowTimeZoneDropdown={shouldShowTimeZoneDropdown ?? true}
        />
      </div>
    );
  }

  renderOnlyVimcalVLogo() {
    return (
      <VimcalVLogo className={"mr-5"} />
    );
  }

  getEventInfoWithIconsContainerClassname(isMobile) {
    if (this.props.isGroupVoteSpreadSheet) {
      return "mt-2 mb-2";
    }
    return isMobile ? "mt-2 mb-2" : "mt-4";
  }

  renderProfileSection() {
    const { isGroupVoteSpreadSheet, info } = this.props;
    if (isGroupVoteSpreadSheet) {
      return null;
    }
    return (
      <HostDetails
        hostDisplayName={this.determineName()}
        profilePhotoSrc={info.profile_photo_url}
        socialLinks={info.social_links}
      />
    );
  }

  determineName() {
    if (IsEmptyObject(this.props.info.user)) {
      return null;
    }

    if (this.props.info.invitee_full_name) {
      return `${CapitalizeFirstLetterOfEveryWord(
        this.props.info.invitee_full_name
      )} and ${this.getSelfName()}`;
    }

    return this.getSelfName();
  }

  getSelfName() {
    if (this.isSchedulingForExec()) {
      return (
        CapitalizeFirstLetterOfEveryWord(this.props.info.user?.full_name) ||
        getUserEmail(this.props.info.user) ||
        ""
      );
    }

    return (
      CapitalizeFirstLetterOfEveryWord(this.props.info.sender_full_name) ||
      CapitalizeFirstLetterOfEveryWord(
        this.props.info.master_account?.full_name
      ) ||
      CapitalizeFirstLetterOfEveryWord(this.props.info.user?.full_name) ||
      getUserEmail(this.props.info.user)
    );
  }

  isSchedulingForExec() {
    return this.props.info.is_scheduling_for_exec;
  }

  determineLargeTitle() {
    const {
      personal_link,
      title,
      invitee_full_name: inviteeName,
      duration,
    } = this.props.info;
    const { companyName } = this.props;

    if (personal_link?.name) {
      return personal_link.name;
    }

    // if we wanted to show title
    // const updatedTitle = replaceVariableBlocksWithValue({
    //   title,
    //   inviteeName: inviteeName || inputName,
    //   companyName,
    // });

    if (title && !doesTitleIncludeVariableBlocks({ title })) {
      return title;
    }

    return determineTitleWithDuration(duration);
  }
}

export default AvailabilityLinkEventInfo;
